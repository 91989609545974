export const SCROLL_DURATION = 200;
export const EPSILON = 1e-10;

export const CHIP_PRICE = 1;
export const MAX_GAME_CHIPS = 60;
export const MAX_DEMO_GAME_CHIPS = 80;

export const CARD_NUMBER = '5174 2500 2157 5634';
export const LOCK_TIME = 30;

export const MIN_DEPOSIT_AMOUNT = 1;
export const MIN_WITHDRAW_AMOUNT = 20;

export const VALID_PASSPHRASE_LENGTH = 20;
export const PASSPHRASE_ERROR_MESSAGE = `The length must be at least ${VALID_PASSPHRASE_LENGTH} characters`;
